import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu,Button,Modal } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpPost, httpUrl } from "../api/httpClient";
import con from "../const";
import { connect } from "react-redux";
import { AppstoreOutlined, FileOutlined, SettingOutlined,UserOutlined, TeamOutlined, WalletOutlined } from '@ant-design/icons';

const SubMenu = Menu.SubMenu;

class Sider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
      menus: [],
      selectedMenu: [],
      userInfo: reactLocalStorage.getObject(con.appName+ "#loginInfo")
    };
  }

  componentDidMount() {
  }


handleChangeTabStatus = (key) => {
    const userInfo = reactLocalStorage.getObject(con.appName + "#adminUser");
    let selectedMenu = [];
    if (key) {
      this.setState({
          selectedMenu: [key],
        });
    } else {
      userInfo.adminAuth.forEach((value, index) => {
          if (value.subMenu) {
          value.subMenu.forEach((c) => {
              if (c.path === this.props.location.pathname) {
                  selectedMenu = [c.name];
                }
            });
        } else {
            if (value.path === this.props.location.pathname) {
                selectedMenu = [value.name];
            }
        }
    });
    this.setState({
        selectedMenu: selectedMenu,
    });
}
};
logout = () => {
    Modal.confirm({
      title: "로그아웃",
      content: "로그아웃 하시겠습니까?",
      okText: "확인",
      cancelText: "취소",
      onOk() {
        httpPost(httpUrl.logout, [], {})
          .then(() => {
            global.location.href = "/";
          })
          .catch((e) => {
            global.location.href = "/";
          });
      }
    })
  };

render() {
    return (
      <Layout.Sider
      style={{height: '100vh', 
      backgroundColor:"#0b3246"
    }}
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => { }}>
        <Menu
          theme="dark"
          selectedKeys={this.state.selectedMenu}
          mode="inline"
          inlineIndent={15}
          onClick={(item) => {
            this.handleChangeTabStatus(item.key);
        }}>
            <div style={{padding: '10px', backgroundColor: '#bfcaff'}}>
              <img src={require('../img/text-logo1.png')}/>
            </div>
            {this.state.userInfo.userId == 'admin' && (
              <Menu.Item
                  key='UserList'
                  >
                  <Link exact="true" to='/user/UserList'>
                      <span className="nav-text">
                    회원관리
                      </span>
                  </Link>
              </Menu.Item>
            )}
            <Menu.Item
                key='WithdrawList'
                >
                <Link exact="true" to='/withdraw/WithdrawList'>
                    <span className="nav-text">
                  신청관리
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key='WithdrawStat'
                >
                <Link exact="true" to='/withdraw/WithdrawStat'>
                    <span className="nav-text">
                   신청통계
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key='ChargeStat'
                >
                <Link exact="true" to='/charge/ChargeStat'>
                    <span className="nav-text">
                   일자별채굴
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key='MyPage'
                >
                <Link exact="true" to='/system/MyPage'>
                    <span className="nav-text">
                   내정보
                    </span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key='logout'
                >
                <div className="nav-text" onClick={this.logout}
                  style={{
                    
                  }}>
                    로그아웃
                  {/* </Link> */}
                </div>
            </Menu.Item>
        </Menu>
      </Layout.Sider>
    );
  }
}



export default Sider;
