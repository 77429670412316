import { Table, Input, Button, Descriptions, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";
const Ditems = Descriptions.Item;


const Search = Input.Search;
const Option = Select.Option;

const UserList = () => {

    const [userList, setUserList] = useState([]);
    const [pagination, setPagination] = useState({
      current: 1,
      total: 0,
      pageSize: 10
    });
    const [searchText, setSearchText] = useState("");
    const [searchType, setSearchType] = useState("NAME");
    const [franchise, setFranchise] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [editingValue, setEditingValue] = useState('');

    useEffect(() => {
        getUserList();
    },[searchText, pagination.current, pagination.pageSize, searchType]);

    const searchTypeHandleChange = (value) => {
        setSearchType(value)
        setIsLoaded(true)
    };

    const handleTableChange = (p, filters, sorter) => {
        console.log(p)
        setPagination(p);
    };

    const onSearch = (value) => {
        setPagination({...pagination, current: 1})
        setSearchText(value)
    };

    const onChangeTag = (value) => {
        setSelectedUser(value)
    };

    const getUserList = () => {

        httpGet(httpUrl.userList, [pagination.pageSize, pagination.current, false, searchText,searchType], {}).then((res) => {
            setUserList(res.data.userList)
            setPagination({...pagination, total: res.data.totalCount})
        }).catch((error) => {});
    };

    const save = () => {
        httpPost(httpUrl.updateGroup, [], {
            idx: editingKey,
            group: editingValue
        })
          .then((res) => {      
            if(res.data) {   
                editEnd()
                getUserList()
            } else {
              alert("수정에 실패하였습니다");
            }
          })
          .catch((e) => {});
    };
    const editEnd = () => {
        setEditingKey('');
        setEditingValue('')
    };

    const resetPassword = (user) => {
        if (confirm(user.id+"님의 비밀번호를 0000으로 변경하시겠습니까?")) {
            httpPost(httpUrl.resetPassword, [user.idx], {}).then((res) => {
                if (res.data) alert("초기화 되었습니다.")
                else alert("오류가 발생하였습니다.")
            }).catch(e=>{
                alert("오류가 발생하였습니다.")
            })
        }
    }
    const resetSecurityPassword = (user) => {
        if (confirm(user.id+"님의 보안 비밀번호를 0000으로 변경하시겠습니까?")) {
            httpGet(httpUrl.resetSecurityPassword, [user.idx], {}).then((res) => {
                if (res.data) alert("초기화 되었습니다.")
                else alert("오류가 발생하였습니다.")
            }).catch(e=>{
                alert("오류가 발생하였습니다.")
            })
        }
    }

    const userListTypeCode = ["ID" , "NAME"]
    const userListTypeString = {
        ID: "아이디",
        NAME: "이름",
    }
    
    const columns = [
        {
            title: "번호",
            dataIndex: "idx",
            key: "idx",
            width: 80,
            className: "table-center",
        },
        {
            title: "가입일",
            dataIndex: "createDate",
            key: "createDate",
            className: "table-center",
            render: (data, row) => (
                <div>{moment(data).format('YYYY-MM-DD')}</div>
            )
        },
        {
            title: "매장",
            dataIndex: "group",
            className: "column-center",
            render: (text, record) => {
                if (record.idx == editingKey) {
                    return <Input value={editingValue} onChange={(e) => setEditingValue(e.target.value)} style={{width: '100px'}}/>;
                }
                return text;
            },
        },
        {
            title: "아이디",
            dataIndex: "id",
            key: "id",
            className: "table-center",
        },
        {
            title: "이름",
            dataIndex: "name",
            key: "name",
            className: "table-center",
        },
        {
            title: "은행",
            dataIndex: "name",
            key: "name",
            className: "table-center",
            render: (data, row) => (
                <div>{row.bankCode} {row.bankAccount} {row.accountHolder}</div>
            )
        },
        {
            title: "비밀번호",
            dataIndex: "idx",
            className: "table-center",
            render: (data, row) => (
                <Button onClick={()=>resetPassword(row)}>초기화</Button>
            )
        },
        {
            title: "보안비밀번호",
            dataIndex: "idx",
            className: "table-center",
            render: (data, row) => (
                <Button onClick={()=>resetSecurityPassword(row)}>초기화</Button>
            )
        },

        {
            title: "매장수정",
            dataIndex: "idx",
            className: "column-center",
            render: (text, record) => {
                return record.idx == editingKey ? (
                    <>
                        <Button onClick={() => save()} type="primary" style={{marginRight: '20px'}}>저장</Button>
                        <Button onClick={() => editEnd()} >취소</Button>
                    </>
                ) : (
                    <Button onClick={() => {
                        setEditingKey(record.idx)
                        setEditingValue(record.group)
                    }}>수정</Button>
                );
            },
        },
    ];
    return (
        <div className="main">
            <div className="main-title">
                회원목록
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '20px'}}>
                <SelectBox
                    value={userListTypeString[searchType]}
                    code={userListTypeCode}
                    codeString={userListTypeString}
                    onChange={(value) => {
                    if (value !== searchType) {
                        searchTypeHandleChange(value);
                    }
                    }}
                    style={{ width: "100px", marginRight: "10px" }}
                />
                <Search
                    placeholder={
                    "아이디/ 이름을 입력해주세요."
                    }
                    enterButton="검색"
                    allowClear
                    onSearch={onSearch}
                    style={{ width: 400 }}
                />
            </div>
            <Table
                id="member-log-table"
                rowKey={(record) => record.idx}
                columns={columns}
                dataSource={userList}
                style={{ marginBottom: "10px" }}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </div>
    );

}

export default UserList;
