export default {
  appName: "BlueWallet",

  /////////////////////////개발/////////////////////////////
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "19001",
  /////////////////////////운영/////////////////////////////
  serverProtocol: "https",
  serverIp: "aback.wikicoin.biz",
  serverPort: "443",

  clientId: "",
  clientSecret: "",
  serverContext: "",
};
