import { Table, Input, Button, Modal, Select } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";


const Search = Input.Search;
const Option = Select.Option;

const WithdrawList = () => {
    const [userInfo, setUserInfo] = useState(reactLocalStorage.getObject(Const.appName+ "#loginInfo")); 
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [list, setList] = useState([]); 
    const [searchStr, setSearchStr] = useState("");
    let dataInterval = null;
    const lastIdx = useRef(0)
    const [audioReady, setAudioReady] = useState(false);
    const audioReadyValue = useRef(false)
    const audio = useRef(new Audio(require('../../sound/alarm.wav')));
    const [searchNode, setSearchNode] = useState(''); 

    useEffect(() => {
        getList();
    },[ pagination.current, pagination.pageSize, searchStr, searchNode]);


    useEffect(() => {
        dataInterval = setInterval(async ()=>{
            const res = await httpGet(httpUrl.withdrawList, [1, 1,searchStr, searchNode], {}, true)
            if (!res) return;
            const withdraws = res.data.withdraws;
            if (withdraws.length != 1) return;
            console.log(lastIdx.current)
            if (lastIdx.current == 0) lastIdx.current = withdraws[0].idx;
            if (lastIdx.current != withdraws[0].idx) {
                getList();
                if (audioReadyValue.current) {
                    audio.current.play();
                    // setTimeout(()=>audio.pause(), 3000)
                }
                lastIdx.current = withdraws[0].idx;
            }
            console.log(res)
        },5000);
        audio.current.addEventListener('ended', handleAudioEnd);
        return ()=>{
            clearInterval(dataInterval);
            audio.current.removeEventListener('ended', handleAudioEnd);
        }
    }, []);

    const enableAudio = () => {
        audio.current.play().then(() => {
            setAudioReady(true);
            audioReadyValue.current = true;
            audio.current.pause(); // 즉시 재생을 멈추고, 나중에 재생 준비 완료
        }).catch((error) => console.error(error));
    };

    const handleAudioEnd = () => {
        audio.current.play();
    };
  
    const toggleAudio = () => {
        if (audioReady) {
            setAudioReady(false)
            audioReadyValue.current = false;
        }
        else {
            enableAudio()
        }
    }

    useEffect(() => {
        
    },[]);

    const onSearch = async (value) => {
        setSearchStr(value)
        getList();
    };

    const getList = async () => {
        const pageSize = pagination.pageSize;
        const pageNum = pagination.current;
        const res = await httpGet(httpUrl.withdrawList, [pageNum, pageSize,searchStr, searchNode], {})
        if (!res) {
            console.log(`getListAsync failed. result: ${res.result}`);
            return;
        }
        setList(res.data.withdraws);
        setPagination({
            ...pagination,
            total: res.data.totalCount
        })
    };

    const handleTableChange = (page) => {
        if (page.current !== pagination.current) {
            setPagination({
                ...pagination,
                current: page.current
            });
        } else if (page.pageSize !== pagination.pageSize) {
            setPagination({
                ...pagination,
                current: 1,
                pageSize: page.pageSize
            });
        }
    };
    
    const columns = [
        // {
        //     title: "No.",
        //     dataIndex: "idx",
        //     className: "column-center",
        // },
        {
            title: "구분",
            dataIndex: "group",
            className: "column-center",
        },
        {
            title: "이름",
            dataIndex: "userName",
            className: "column-center",
        },
        {
            title: "아이디",
            dataIndex: "userId",
            className: "column-center",
        },
        {
            title: "수량",
            dataIndex: "amount",
            className: "column-center",
            render: (data) => <div>{data} BLC</div>
        },
        {
            title: "은행명",
            dataIndex: "bank",
            className: "column-center",
        },
        {
            title: "예금주",
            dataIndex: "bankName",
            className: "column-center",
        },
        {
            title: "계좌번호",
            dataIndex: "bankAddress",
            className: "column-center",
        },
        {
            title: "일시",
            dataIndex: "createDate",
            className: "column-center",
            render: (data) => <div>{moment(data).format("YYYY-MM-DD HH:mm:ss")}</div>
        },

        {
            title: "상태",
            dataIndex: "status",
            className: "column-center",
            render: (data,row) => 
            <div style={{fontWeight:'bold', color: data == 0 ? 'blue' : data == 1 ? 'black' : 'gray'}}>
                {withdrawStatus[data]}
            </div>
        },
        {
            title: "상태",
            dataIndex: "status",
            className: "column-center",
            render: (data,row) => 
            <div>
                {data == 0 && (
                    <>
                    <Button onClick={()=>{onSelectStatus(row.idx, 1)}}>완료처리</Button>
                    <Button onClick={()=>{onSelectStatus(row.idx, 2)}} style={{marginLeft: '10px'}}>취소처리</Button>
                    </>
                )}
            </div>
        },
        {
            title: "삭제",
            dataIndex: "status",
            className: "column-center",
            render: (data,row) => 
            <div>
                    <Button onClick={()=>{onDelete(row.idx)}}>삭제</Button>
            </div>
        },

        
    ];
    const onSelectStatus = (idx, value) => {
        if (value == 1) {
            if (!confirm(idx + '번 신청을 완료하시겠습니까?')) return;
        }
        if (value == 2) {
            if (!confirm('취소하는 경우 코인이 다시 신청자에게 지급됩니다. '+idx+'번 신청을 정말 취소하겠습니까?')) return;
        }
        httpPost(httpUrl.withdrawUpdate, [],{
            idx:idx,
            status: value,
        })
        .then((res) => {
            console.log('222222222'+ value)
            getList();
          })
          .catch((e) => {
            console.log('33333333')
            Modal.error({
                title: "변경 오류",
                content: "오류가 발생했습니다. 관리자에게 문의해주세요."
            });
          });
      };
      const onDelete = (idx) => {
        if (!confirm(idx + '번 신청을 삭제하시겠습니까?')) return;
          httpPost(httpUrl.withdrawDelete, [idx],{})
          .then((res) => {
              getList();
            })
            .catch((e) => {
              Modal.error({
                  title: "삭제 오류",
                  content: "오류가 발생했습니다. 관리자에게 문의해주세요."
              });
            });
        };
    const onDownload = () => {
        httpGet(httpUrl.withdrawExcelList, [
            searchStr, 
        ],{}).then((res) => {
            const excelJson = parseExcelJson(res.data.withdraws);
            const ws = utils.json_to_sheet(excelJson, { skipHeader: true });
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "sheet1");
            writeFile(wb, "매도목록.xlsx");
        });
    };

    const parseExcelJson = (data) => {
        let result = [
          {
            idx: '번호',
            userName:'이름',
            userId:'아이디',
            amount:'수량',
            bank:'은행명',
            bankName:'예금주',
            bankAddress:'계좌번호',
            createDate: '일시',
            status:'상태'
          },
        ];
        data.forEach((item) => {
          result.push({
            idx: item.idx,
            userName: item.userName,
            userId: item.userId,
            amount: item.amount,
            bank: item.bank,
            bankName: item.bankName,
            bankAddress: item.bankAddress,
            createDate: moment(item.createDate).format("YYYY-MM-DD HH:mm:ss"),
            status: withdrawStatus[item.status]
          });
        });
    
        return result;
      };
    
    return (
        <div className="main">
            <div className="main-title">
                신청관리
                <div style={{float: 'right'}}>
                    <span style={{fontSize: '20px', marginRight: '10px'}}>{audioReady ? "ON" : "OFF"}</span>
                    <Button onClick={toggleAudio}>{audioReady ? "소리끄기" : "소리켜기"}</Button>
                    <Button onClick={()=>audio.current.pause()} style={{marginLeft:'10px'}}>소리중지</Button>
                </div>
            </div>
            <div className="top-bar">     
                <div>
                    <Search
                        placeholder="이름을 검색하세요"
                        enterButton
                        allowClear
                        onSearch={onSearch}
                        value={searchStr}
                        onChange={(e)=>{
                            setSearchStr(e.target.value.trim());
                        }}
                    />
                    <Select
                      style={{ width: "100px" }}
                      value={searchNode}
                      onChange={value => {
                        setSearchNode(value)
                      }}>
                      <Option key={0} value={''}>
                        전체보기
                      </Option>
                        {userInfo.adminAuthNode.map((row, idx)=>
                            <Option key={idx+1} value={row}>
                                {row}
                            </Option>)}
                    </Select>
                </div>            
                <div>
                    <Button className="download-btn"
                        onClick={() => onDownload()}
                    > 
                    <div>
                        <img src={excelPng} alt="" />다운로드
                    </div>
                    </Button>
                </div>
            </div>
           <div className="top-table">
                <div className="top-padding"></div>
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
             </div>
        </div>
    );

}

export default WithdrawList;
